<template>
  <div>
    <button-bar :buttons="invoiceButtons" @onSelect="buttonSelected" />

<div v-if="nameChangeButtonSelected">
<div>
  <data-table
      :headers="personalInfoEditHistoryHeaders"
      :entries="personalInfoEditHistory"
      :loading="Nameloader"
      :pagination-total-count="NametotalCount"
      :pagination-per-page="NameperPage"
      :pagination-current-page="NamecurrentPage"
      @onPaginate="onPaginateName($event)"
    >
    <template #changedOn="{ item }"> {{ formatDate(item.changed_on) }}</template>
    <template #changedFrom="{ item }"><span><span v-if="item.changed_from">{{ item.changed_from.firstName }} {{ item.changed_from.lastName }}</span></span></template>
    <template #changedTo="{ item }"><span><span v-if="item.changed_to">{{ item.changed_to.firstName }} {{ item.changed_to.lastName }}</span></span></template>         
   </data-table>
</div>
</div>
<div v-if="taxesButtonSelected">
    <div class="taxes-tab-div">
      <div class="col-12">
        <p class="label">Filters</p>
        <div class="container">
          <div class="label-box">
            <span class="label-text">Tax Year</span>
            <span class="icon-dropdown-right dropdownRight"></span>  
          </div>
          <div class="dropdown-container">
            <select v-model="currentYear" class="dropdown" disabled>
              <option :value="currentYear">{{ currentYear }}</option>
            </select>
          </div>
          <div class="additional-elements">
            <div >
              <button @click="openModal" class="button secondary exportcsv" v-show="!disableDownload">
              Export as CSV
            </button>
            </div>
            <!-- <button class="button secondary">Manage Fields Mapping</button> -->
            <div class="col-6 search-cont">
              <div class="search-container">
                <input v-model="searchTerm" class="deposit-search" type="search" placeholder="Search Driver Name"/>
                <button type="submit"><span v-if="searchIcon()" class="icon-x deposit-icon" @click="resetSearch" ></span> <span v-else class="icon-search deposit-icon"></span></button>
              </div>

            <button class="button secondary searchButtonAlign"  @click="getSearchPartnersData(true)">
              <span class="icon-search deposit-icon" ></span>
            </button>
            </div>
              
          </div>
        </div>
        <base-filter name="Tax Regenerated" :options="taxGeneratedFilterOptions" type="radio" :filterToggleOn="true" @onFilter="fetchFilteredResults('times-generated', $event)" />
        <base-filter name="Refile IRS" :options="refileFilterOptions" type="radio" :filterToggleOn="true" 
        @onFilter="fetchFilteredResults('refile-irs', $event)"/>
        <base-filter name="Refile Status" :options="refileStatusFilterOptions" type="radio" :filterToggleOn="true" 
        @onFilter="fetchFilteredResults('refile-status', $event)"/>
      </div>
    </div>
    <div>
      <p>Showing {{ totalCount }} Candidates</p>
    </div>
    <div class="select-all-checkbox">
      <input type="checkbox" class="checkbox-input-deposit" id="selectAll" v-model="selectAll" @change="selectAllPartners">
      <label for="selectAll">Select All</label>
    </div>
    <div>
      <data-table
      :headers="headers"
      :loading="loader"
      :entries="correctedPartners"
      :pagination-total-count="totalCount"
      :pagination-per-page="perPage"
      :pagination-current-page="currentPage"
      @onPaginate="onPaginate($event)"
    >
    <template #fullname="{ item }"> <input type="checkbox" class="checkbox-input-deposit" @click="multiSelect(item.partnerID, $event)" v-model="selectedPartners" :value="item.partnerID" > {{ item.fullname }}</template>
    </data-table>
    </div>

    <div id="export-csv" class="modal" aria-hidden="true">
      <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
        <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby">
          <div class="Infobluelogo">
            <span><img src="@/assets/images/logos/infoblue.svg" /></span>
          </div>
          <div class="modal-content">
            <div>
              <p class="textColor">
                The {{selectedPartners.length}} drivers have been exported for refile</p>
              <p class="textColor"> IRS ,Do you want also change the IRS flag for</p>
              <p class="textColor">same drivers from YES to NO?</p>
            </div>
  
            <div class="exportCsvDiv">
              <button class="button secondary clickButton" @click="updateRefileStatus" >
                Yes,Change the Flag to No
              </button>
              <button class="button secondary noButtonPopup" @click="closeModal">
                No,Keep it Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { BaseFilter, DataTable, TableHeader } from "@/components";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/util/Base64Encoding";
import { downloadFile } from "@/filters/index";
import micromodal from "micromodal";
import ButtonBar from "@/components/buttons/ButtonBar";
import { DateTime } from "luxon";
export default {
  name: "TaxesTab",
  components: {
    DataTable,
    BaseFilter,
    ButtonBar
  },
  data() {
    return {
      currentYear: new Date().getFullYear() - 1,
      correctedPartners: [],
      selectedPartners:[],
      loader: true,
      totalCount: 0,
      totalCountForPagination: 0,
      currentPage: 1,
      offset: 0,
      perPage: 50,
      searchTerm: "",
      filterGereratedDocVal:'>= 0',
      filterRefileIRSVal:"",
      disableDownload:true,
      selectAll: false,
      filterRefileStatus:"",
      nameChange:false,
      selectedButton: "taxestab",
      personalInfoEditHistory: [],
      nameChangeButtonSelected:false,
      taxesButtonSelected:true,
      NametotalCount:0,
      NameperPage:50,
      NamecurrentPage:1,
      Nameoffset:0,
      Nameloader: true,
    };
  },

  mounted() {
    this.getCorrectedData('%');
  },

  computed: {
    headers() {
      return [
        new TableHeader({
          label: "DriverName",
          cellContents: "fullname",
          name: "fullname",
        }),
        new TableHeader({
          label: "Location",
          cellContents: "LocationName",
          name: "location",
        }),
        new TableHeader({
          label: "Refile IRS",
          cellContents: "nec_refile_irs",
          name: "refile_irs",
        }),
        new TableHeader({
          label: "No. of time tax document generated",
          cellContents: "timesGenerated",
          name: "times_generated",
        }),
        new TableHeader({
          label: "Status",
          cellContents: "nec_refile_status",
          name: "status",
        }),
      ];
    },

    taxGeneratedFilterOptions() {
      return [
        { id: "never", name: "never", label: "Never", checked: false },
        { id: "once", name: "once", label: "Once", checked: true },
        { id: "morethanonce", name: "morethanonce", label: "More than once",checked: false},
        // { id: "nameChange", name: "nameChange", label: "Name Change",checked: false},
      ];
    },

    refileFilterOptions() {
      return [
        { id: "true", name: "true", label: "Yes", checked: false },
        { id: "false", name: "false", label: "No", checked: false },
      ];
    },

    refileStatusFilterOptions() {
      return [
        { id: "completed", name: "completed", label: "Completed", checked: false },
        { id: "notprocessed", name: "notprocessed", label: "Not processed", checked: false },
      ];
    },

    invoiceButtons() {
      return [
        { id: "taxestab", label: "Taxes" },
        { id: "namechangetab", label: "Name Change" },
      ];
    },
    // taxesButtonSelected() {
    //   // this.nameChangeButtonSelected=false
    //   // this.getDraftedInvoices(this.perPage, 0)
    //   console.log("taxestab",this.selectedButton)
    //   return this.selectedButton === "taxestab";
    // },
    // nameChangeButtonSelected() {
    //   // this.taxesButtonSelected=false;
    //   // this.getApprovedInvoices(this.perPage, 0)
    //   console.log("namechangetab",this.selectedButton)
    //   return this.selectedButton === "namechangetab";
    // },
    personalInfoEditHistoryHeaders() {
            return [
                new TableHeader({ label: "Changed On", headerClass: "cell15 text-left", name: "changedOn", cellContents: "changed_on" }),
                new TableHeader({ label: "Driver Name", headerClass: "cell15", name: "changedFrom", cellContents: "changed_from" }),
                new TableHeader({ label: "Changed To", headerClass: "cell15", name: "changedTo", cellContents: "changed_to" }),
                new TableHeader({ label: "Changed By", headerClass: "cell20", name: "changedBy", cellContents: "changed_by" }),
                new TableHeader({ label: "Approved By", headerClass: "cell20", name: "approvedBy", cellContents: "approved_by" }),
                // new TableHeader({ label: "Approved On", headerClass: "cell15", name: "approvedOn", cellContents: "approved_on" }),
            ];
        },

  },
  methods: {
    closeModal() {
      this.selectAll=false;
      this.selectedPartners = [];
      this.disableDownload=true;
      this.resetPaginator();
      micromodal.close("export-csv");
      this.getCorrectedData('%')
    },
    openModal(){
      this.exportCorrectedCSV();
      micromodal.show("export-csv");
    },
    fetchFilteredResults(type, event) {
      switch(type) {
        case "times-generated":
        this.nameChange = false;
          if(!event[0]){
            this.filterGereratedDocVal= '>= 0'
          }
          if(event[0] == 'never'){
            this.filterGereratedDocVal= '= 0'
          }
          else if(event[0] == 'once'){
            this.filterGereratedDocVal= '= 1'
          }
          else if(event[0] == 'more than once'){
            this.filterGereratedDocVal= '> 1'
          }
          else if(event[0] == 'name change'){
            this.nameChange = true;
          }
          break;
        case "refile-irs":
          if(event.length){
            this.filterRefileIRSVal = event[1].id;
          }
          else{
            this.filterRefileIRSVal = "'true','false'"
          }
          break;
          case "refile-status":
          if(!event[0]){
            this.filterRefileStatus= ''
          }
          if(event[0] == 'completed'){
            this.filterRefileStatus= 'completed'
          }
          else if(event[0] == 'not processed'){
            this.filterRefileStatus= 'not_completed'
          }
          break;
      }
      this.getCorrectedData('%')
      
    },

    resetPaginator() {
      this.currentPage = 1;
      this.offset = 0;
    },

    onPaginate(event) {
      this.loader = true;
      this.currentPage = Number(event.pageNumber);
      this.offset = event.offset;
      this.selectAll=false;
      this.selectedPartners=[];
      this.getCorrectedData('%');
    },

    resetPaginatorName() {
      this.NamecurrentPage = 1;
      this.Nameoffset = 0;
    },

    onPaginateName(event) {
      this.Nameloader = true;
      this.NamecurrentPage = Number(event.pageNumber);
      this.Nameoffset = event.offset;
      this.getNameChangepartnerhistory();
    },

     getCorrectedData(searchTerm) {
      this.loader =true;
      let payload={
        "limit": this.perPage,
        "offset": this.offset,
        "taxYear": this.currentYear,
        "refileirs": this.filterRefileIRSVal,
        "timesGenerated":this.filterGereratedDocVal,
        "searchTerm":searchTerm,
        "refilestatus":this.filterRefileStatus,
        "nameChange":this.nameChange
      }
      restApi.post("tax/necCorrectedData", encodeWithParam(payload)).then((data)=>{
        if(data.data.success){
          this.correctedPartners = data.data.data.rows;
          this.totalCount = data.data.totalCount
          this.loader = false
        }
      })
    },

    getSearchPartnersData(val) {
      if(val){
      this.resetPaginator();}
      let payload={
        "limit": this.perPage,
        "offset": this.offset,
        "taxYear": this.currentYear,
        "refileirs": this.filterRefileIRSVal,
        "timesGenerated":this.filterGereratedDocVal,
        "searchTerm":this.searchTerm? `%${this.searchTerm}%`:'%',
        "refilestatus":this.filterRefileStatus
      }
      restApi.post("tax/necCorrectedData", encodeWithParam(payload)).then((data)=>{
        if(data.data.success){
          this.correctedPartners = data.data.data.rows;
          this.totalCount = data.data.totalCount
          this.loader = false
        }
      })
    },

    searchIcon() {
      return this.searchTerm !== "";
    },
    
    resetSearch() {
      this.searchTerm = "";
      this.currentPage = 1;
      this.getCorrectedData('%');
    },

    multiSelect(val, e){
      if(e.target.checked){
            if (val.id != this.selectedPartners.map((item) => item.id) && this.selectedPartners.length > 0) {
                this.selectedPartners.push(val)
                this.selectAll = this.selectedPartners.length === this.correctedPartners.length;
            }
            else{
              this.selectedPartners.push(val)
              this.selectAll = this.selectedPartners.length === this.correctedPartners.length;
            }
      }
      else{
        this.selectedPartners.splice(this.selectedPartners.indexOf(val), 1)
        this.selectAll = this.selectedPartners.length === this.correctedPartners.length;
      }
      this.selectedPartners.length ? this.disableDownload=false :this.disableDownload=true
    },
    exportCorrectedCSV(){
      let payload={
        "taxYear": this.currentYear,
        "partnerIDs":this.selectedPartners
      }
      restApi.post("tax/necCSVCorrectedData", encodeWithParam(payload)).then((data)=>{
        if(data.data?.error){
            return data.data.message
          }
          else{
            downloadFile(data.data,`1099NEC_CSV_Template_${this.currentYear}.csv`);
            this.resetPaginator();
          }
      })
    },
    updateRefileStatus(){
      let payload={
        "taxYear": this.currentYear,
        "partnerIDs":this.selectedPartners
      }
      restApi.post("tax/updateNECRefileIRS", encodeWithParam(payload))
      this.closeModal()
      this.getCorrectedData('%')    
    },
    selectAllPartners() {
      if (this.selectAll) {
        this.selectedPartners = this.correctedPartners.map(partner => partner.partnerID);
        this.selectedPartners.length ? this.disableDownload=false :this.disableDownload=true
      } else {
        this.selectedPartners = [];
        this.selectedPartners.length ? this.disableDownload=false :this.disableDownload=true
      }
    },
    updateSelectAll(val,e) {
      console.log(val,e,'val e');
      // Update the state of "Select All" based on individual checkboxes
    this.multiSelect(val,e);
      this.selectAll = this.selectedPartners.length === this.correctedPartners.length;
    },
    buttonSelected(button) {
      this.selectedButton = button.id;
      if(this.selectedButton === 'namechangetab') {
      this.selectAll=false;
      this.selectedPartners = [];
      this.disableDownload=true;
      this.resetPaginator();
        this.taxesButtonSelected=false
        this.nameChangeButtonSelected=true
        this.getNameChangepartnerhistory();
      }
      else if(this.selectedButton=='taxestab')
      {
        this.resetPaginatorName();
        this.nameChangeButtonSelected=false
        this.taxesButtonSelected=true
        this.getCorrectedData('%')
      }
    },
    getNameChangepartnerhistory() {
      this.Nameloader =true;
      let payload={
        "limit": this.NameperPage,
        "offset": this.Nameoffset,
      }
      restApi.post("tax/partnerNameChangeHistory",encodeWithParam(payload)).then((data)=>{
        if(data.data.success){
          this.personalInfoEditHistory=data.data.data
          this.NametotalCount = data.data.totalCount;
          this.Nameloader = false;
        }
      })
    },
    formatDate(date) {
            return DateTime.fromISO(date).setZone("America/Los_Angeles").toFormat('hh:mm a, LL/dd/y')
    },
  },
};
</script>

<style scoped>
.checkbox-input-deposit{
  width: 16px;
    cursor: pointer;
    height: 15px;
    margin-top: 2px;
}
.container {
  display: flex;
  align-items: center;
  padding: 0px 0px;
  margin: -5px 0px ;
}

.label-box {
  width: 92px;
  height: 30px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding: 5px 5px;
  font-size: 14px;
  background-color: #f4f4f4;
  border-radius: 5px;
}

.label-text {
  flex: 1;
  color: #868585;
  /* line-height: 2px; */
  position: absolute;
}

.dropdown-container {
  margin-left: 10px;
}

.triangle {
  font-size: 10px;
  color: #868585;
}

.dropdown {
  width: 120px;
  height: 30px;
  padding: 5px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.additional-elements {
  display: flex;
  margin-left: 500px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.noButtonPopup {
  margin-left: 10px;
  padding: 10px;

}
.textColor {
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  padding: 0px 5px;
  margin: 0px 5px !important;
}
.noButtonPopup:hover {
  background-color: #16789e !important;
  color: #fff !important;
}

.clickButton:hover {
  background-color: #16789e !important;
  color: #fff !important;
}

.Infobluelogo {
  margin: 8px 10px;
  height: 30px;
  width: 30px;
}

.exportCsvDiv {
  margin: 20px 0px;
  padding: 0px;

}
.modal-content{
  margin-left: 5px !important;
}
.modal-container {
  background-color: #fff;
  padding: 0 16px 16px 16px;
  width: 100%;
  max-width: 470px;
  max-height: 100%;
  border-radius: 0px 0px 0px 0px;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
  font-family: "Open Sans";
}
.dropdownRight{
  /* height: 15px !important; */
  width: 70px !important;
  color: #868585 !important;
  position: relative;
  left: 58px;
  font-size: 1.25rem;

}
.select-all-checkbox {
    display: flex;
    align-items: center;
    margin: 0px 20px;
  }
  .select-all-checkbox label {
    margin: 10px 10px; /* Adjust the margin as needed */
  }
  .search-cont{
    display: flex;;
  }
  .searchButtonAlign{
    margin-left: 10px;
    padding: 10px;
    height: 39px;
    width: 39px;
  }
  .taxes-tab-div{
    margin: 0px 0px;
  }
  .search-container{
    border: 1px #868585 !important;
  }
</style>
